import React from "react"
import "../assets/styles/global.css"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
// import Rgpd from "../components/Bar/rgpd"
import BasicLayout from "../layouts/BasicLayout"
import { InlineWidget } from "react-calendly"
import { SEO_OPTIONS_MEETING } from "../const/SEOOptions"
import {DownloadInTab} from "../services/EbookInTab";
import {css} from "@emotion/react";

const MeetingPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "main-logo-blue.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)


  return (
    <BasicLayout
    SEOOptions={SEO_OPTIONS_MEETING}
    >
      <div css={MeetingPageStyles}>
        <div className="header">
          <div className="logo">
            <Img
              fluid={data.logo.childImageSharp.fluid}
              alt="Focus Datascience logo"
              placeholderStyle={{ backgroundColor: `white` }}
            />
          </div>

          <h1 className="title-1">Thank you!</h1>
          <h1 className="title-2">1. book your free meeting</h1>
        </div>
        <div className="wrapper">

          <InlineWidget url={"https://calendly.com/nadiapetiot/discovery-call"} />

        </div>
        <div style={{ display: "grid", margin: "auto" }}>
          <h1 className="title-2">2. Download your ebook</h1>
          <button onClick={() => DownloadInTab()}>DOWNLOAD THE EBOOK</button>
        </div>
      </div>
      {/* <Rgpd location={location}/> */}
    </BasicLayout>
  )
}

export default MeetingPage

const MeetingPageStyles = css`
  .header {
    text-align: center;
  }

  .logo {
    width: 11.52rem;
    display: block;
    margin: auto;
  }

  .wrapper {
    display: grid;
    grid-auto-columns: 1fr;
    margin: 10px auto;
    justify-content: center;
    max-width: 1200px;
  }

  .one {
    margin: auto;
  }

  .title-1 {
    font-size: 50px;
    color: #133c9e;
  }

  .title-2 {
    font-size: 30px;
    text-align: center;
  }

  .accroche {
    font-size: 22px;
    margin-top: 0;
  }

  button {
    display: inline-grid;
    align-content: center;
    text-decoration: none;
    height: 50px;
    width: 243px;
    text-align: center;
    font-weight: 600;
    background-color: #133c9e;
    color: white;
    cursor: pointer;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 80px;
    border-radius: 10px;
    border: none;
    box-shadow: 5px 8px 8px rgb(0 0 0 / 20%);

    &:focus {
      outline: none;
    }
  }

  @media screen and (max-width: 600px) {
    .two {
      text-align: center;
      margin: auto;
    }
  }
`
